#root {
  height: 100%;
}
td.ant-table-cell {
  padding: 4px 8px !important;
}
.ant-spin-nested-loading, .ant-spin-container, .ant-spin-spinning{
  height: 100% !important;
  max-height: 100% !important;
}
.site-content {
  background-color: white;
  padding: 20px;
}
